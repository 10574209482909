
'use strict';


var TAB_ID = 'configTab';
var TAB_LABEL = 'Configuration';
var POST_PROCESS_LABEL = 'Postprocess Style';
var DISPLAY_EDGES = 'Display edges';
var DISPLAY_EDGES_DESC = 'Controls whether edge lines should be drawn around objects';
var GRAYSCALE = 'Grayscale';
var GRAYSCALE_DESC = 'Converts the rendering to grayscale with no color';
var BRIGHTNESS = 'Brightness';
var CONTRAST = 'Contrast';
var PRESERVE_COLOR = 'Preserve Color';
var PRESERVE_COLOR_DESC = 'Maintains the hue by pulling the color back into gamut if its too high';
var LEVELS = 'Levels';
var REPEATS = 'Repeats';
var ROTATION = 'Rotation';
var OUTLINE_RADIUS = 'Outline Radius';
var OUTLINE_NOISE = 'Outline Noise';
var OUTLINE_NOISE_DESC = 'Adds an outline noise to the edges';

var NPRRenderOptionsPanel = function NPRRenderOptionsPanel(viewer, extension, title) {var _this = this;
  var PANEL_ID = 'adsk_npr_renderoptions_panel_' + viewer.id;
  var opts = { heightAdjustment: 90, width: 460 };
  Autodesk.Viewing.UI.SettingsPanel.call(this, viewer.container, PANEL_ID, title, opts);
  this.setGlobalManager(viewer.globalManager);
  this.container.classList.add('viewer-settings-panel');
  this.viewer = viewer;

  // Add a default tab called Render Options
  this.addTab(TAB_ID, TAB_LABEL, { className: 'config' });
  this.selectTab(TAB_ID);

  // DropDown "Postprocess Style"
  var postProcessList = ['none', 'edging', 'cel', 'graphite', 'pencil'];

  this.postProcess = this.addDropDownMenu(TAB_ID, POST_PROCESS_LABEL, postProcessList, 0, function (event) {
    var value = event.detail.value;

    // load them all up, since someone is fiddling with style
    extension.preloadPostProcessStyle();
    extension.setPostProcessParameter("style", postProcessList[value]);

    var styleVal = Math.floor(parseFloat(value));
    var enable = styleVal != 0.0;
    _this.edgesCheckbox.setDisabled(!enable);
    _this.grayscaleCheckbox.setDisabled(!enable);
    _this.brightnessSlider.setDisabled(!enable);
    _this.contrastSlider.setDisabled(!enable);
    _this.preserveColorCheckbox.setDisabled(!enable);
    _this.levelsSlider.setDisabled(styleVal !== 2.0);
    _this.repeatsSlider.setDisabled(styleVal < 3.0);
    _this.rotationSlider.setDisabled(styleVal < 3.0);
    _this.outlineRadiusSlider.setDisabled(!enable);
    _this.outlineNoiseCheckbox.setDisabled(!enable);
  });
  this.postProcessDropdown = this.getControl(this.postProcess);

  // Checkbox "Display Edges"
  this.edgesToggle = this.addCheckbox(TAB_ID, DISPLAY_EDGES, true, function (checked) {
    extension.setPostProcessParameter("edges", checked);
  }, DISPLAY_EDGES_DESC);
  this.edgesCheckbox = this.getControl(this.edgesToggle);

  // Checkbox "Gray Scale"
  this.grayscaleToggle = this.addCheckbox(TAB_ID, GRAYSCALE, true, function (checked) {
    extension.setPostProcessParameter("grayscale", checked);
  }, GRAYSCALE_DESC);
  this.grayscaleCheckbox = this.getControl(this.grayscaleToggle);

  // Slider "Brightness"
  this.brightness = this.addSlider(TAB_ID, BRIGHTNESS,
  -1.0, 1.0, 0.0,
  function (event) {
    var value = event.detail.value;
    extension.setPostProcessParameter('brightness', parseFloat(value));
  },
  { step: 0.01 });

  this.brightnessSlider = this.getControl(this.brightness);

  // Slider "Contrast"
  this.contrast = this.addSlider(TAB_ID, CONTRAST,
  -1.0, 1.0, 0.0,
  function (event) {
    var value = event.detail.value;
    extension.setPostProcessParameter('contrast', parseFloat(value));
  },
  { step: 0.01 });

  this.contrastSlider = this.getControl(this.contrast);

  // Checkbox "Preserve Color"
  this.preserveColorToggle = this.addCheckbox(TAB_ID, PRESERVE_COLOR, true, function (checked) {
    extension.setPostProcessParameter("preserveColor", checked);
  }, PRESERVE_COLOR_DESC);
  this.preserveColorCheckbox = this.getControl(this.preserveColorToggle);

  // Slider "Levels"
  this.levels = this.addSlider(TAB_ID, LEVELS, 2.0, 2.99, 0,
  function (event) {
    var value = event.detail.value;
    extension.setPostProcessParameter("levels", 2 * parseFloat(value));
  }, { step: 0.01 });

  this.levelsSlider = this.getControl(this.levels);

  // Slider "Repeats"
  this.repeats = this.addSlider(TAB_ID, REPEATS, 3.0, 5.0, 3.0,
  function (event) {
    var value = event.detail.value;
    // "graphite" value ranges from 3.0 - 3.99;
    // "pencil" value ranges from 4.0 - 5.0;
    extension.setPostProcessParameter("repeats", parseFloat(value));
  }, { step: 0.01 });
  this.repeatsSlider = this.getControl(this.repeats);

  // Slider "Rotation"
  this.rotation = this.addSlider(TAB_ID, ROTATION,
  0.0, 1.0, 0.0,
  function (event) {
    var value = event.detail.value;
    extension.setPostProcessParameter("rotation", parseFloat(value));
  },
  { step: 0.01 });

  this.rotationSlider = this.getControl(this.rotation);

  // Checkbox "Preserve Color"
  this.outlineNoiseToggle = this.addCheckbox(TAB_ID, OUTLINE_NOISE, false, function (checked) {
    extension.setPostProcessParameter("outlineNoise", checked);
  }, OUTLINE_NOISE_DESC);
  this.outlineNoiseCheckbox = this.getControl(this.outlineNoiseToggle);

  // Slider "Rotation"
  this.outlineRadius = this.addSlider(TAB_ID, OUTLINE_RADIUS,
  1.0, 3.0, 1.0,
  function (event) {
    var value = event.detail.value;
    extension.setPostProcessParameter("outlineRadius", parseFloat(value));
  },
  { step: 0.01 });

  this.outlineRadiusSlider = this.getControl(this.outlineRadius);
};

NPRRenderOptionsPanel.prototype = Object.create(Autodesk.Viewing.UI.SettingsPanel.prototype);
NPRRenderOptionsPanel.prototype.constructor = NPRRenderOptionsPanel;

export { NPRRenderOptionsPanel };