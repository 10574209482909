import { NPRRenderContextExtension } from './NPRRenderContextExtension';
import { NPRRenderOptionsPanel } from './NPRRenderOptionsPanel';
import { locales } from './NPRLocales';

var SETTINGS_BUTTON_LABEL = 'Non-Photorealistic Rendering';

var Extension = Autodesk.Viewing.Extension;

/**
                                             * Provides UI controls for NPR settings
                                             * 
                                             * The extension id is: `Autodesk.NPR`
                                             * 
                                             * @example
                                             *    viewer.loadExtension('Autodesk.NPR');
                                             * 
                                             * @memberof Autodesk.Viewing.Extensions.NPR
                                             * @see {@link Autodesk.Viewing.Extension} for common inherited methods.
                                             * @constructor
                                             * @param {Autodesk.Viewing.Viewer3D} viewer - Viewer instance.
                                             * @param {Object} options - Not used.
                                             */
export function NPRExtension(viewer, options) {
  Extension.call(this, viewer, options);
  this.viewer = viewer;
  this._configButtonId = null;
  this._panel = null;
  this.hasUI = Autodesk.Viewing.GuiViewer3D && viewer instanceof Autodesk.Viewing.GuiViewer3D;
  this.openPanel = this.openPanel.bind(this);
  this._initButtonConfig = this._initButtonConfig.bind(this);
};

NPRExtension.prototype = Object.create(Autodesk.Viewing.Extension.prototype);
NPRExtension.prototype.constructor = NPRExtension;

/**
                                                    * Load the NPR extension.
                                                    * It will fail to load when running a headless viewer.
                                                    */
NPRExtension.prototype.load = function () {
  this.extendLocalization(locales);
  this.nprRenderContextExtension = new NPRRenderContextExtension(this.viewer.impl.renderer(), this.viewer);
  this.nprRenderContextExtension.load();
  this.viewer.impl.renderer().postShadingManager().registerPostProcessingExtension(this.nprRenderContextExtension);
  return true;
};

/**
    * Unload the NPR extension.
    */
NPRExtension.prototype.unload = function () {
  if (this._configButtonId !== null) {
    this.viewer.viewerSettingsPanel.removeConfigButton(this._configButtonId);
    this._configButtonId = null;
  }
  if (this._panel) {
    this._panel.uninitialize();
    this._panel = null;
  }
  if (this.nprRenderContextExtension) {
    this.viewer.impl.renderer().postShadingManager().removePostProcessingExtension(this.nprRenderContextExtension);
    this.viewer.impl.invalidate(true, true, true);
    this.nprRenderContextExtension = null;
  }
  return true;
};

/**
    * Invoked by the viewer when the toolbar UI is available. 
    * Adds a button to the Settings panel.
    *
    * @alias Autodesk.Viewing.Extensions.NPRExtension#onToolbarCreated
    */
NPRExtension.prototype.onToolbarCreated = function () {
  this._initButtonConfig();
};

/**
    * Opens the NPR Render Options panel.
    *
    * @alias Autodesk.Viewing.Extensions.NPRExtension#openPanel
    */
NPRExtension.prototype.openPanel = function () {
  if (!this._panel) {
    this._panel = new NPRRenderOptionsPanel(this.viewer, this.nprRenderContextExtension, SETTINGS_BUTTON_LABEL);
  }
  this._panel.setVisible(true);
};

/**
    * Changes post-processing setting parameters. The supported param/value combinations are:
    * 
    * - "style": either "edging", "cel", "graphite", "pencil" or `null` to turn post-processing off.
    * - "edges": `boolean`
    * - "idEdges": `boolean`
    * - "normalEdges": `boolean` 
    * - "depthEdges": `boolean`
    * - "brightness": `Number`
    * - "contrast": `Number`
    * - "grayscale": `boolean`
    * - "preserveColor": `boolean`
    * - "levels": `Number`
    * - "repeats": `Number`
    * - "rotation": `Number` between 0 and 1, around circle (e.g. 0.5 == pi radians, 1.0 == 2*pi)
    *
    * Fires event {@link RENDER_OPTION_CHANGED_EVENT}.
    *
    * @param {string} param - Either "style", "edges", "idEdges", "normalEdges", "depthEdges", "brightness", 
    *                         "contrast", "grayscale", "preserveColor", "levels", "repeats" or "rotation".
    * @param {*} value - type depends on the specified `param`.
    *
    * @alias Autodesk.Viewing.Extensions.NPRExtension#setParameter
    */
NPRExtension.prototype.setParameter = function (param, value) {
  this.nprRenderContextExtension.setPostProcessParameter(param, value);
};

/**
    * Adds a button to the Settings Panel.
    * @private
    */
NPRExtension.prototype._initButtonConfig = function () {
  var settingsPanel = this.viewer.getSettingsPanel();
  if (!settingsPanel) {
    this.addEventListener(av.SETTINGS_PANEL_CREATED_EVENT, this._initButtonConfig, { once: true });
    return;
  }
  this._configButtonId = settingsPanel.addConfigButton(SETTINGS_BUTTON_LABEL, this.openPanel);
};


/**
    * Register the extension with the extension manager.
    */
Autodesk.Viewing.theExtensionManager.registerExtension('Autodesk.NPR', NPRExtension);