/**
 * Include each locale json file and return it in an object
 * that can be consumed by i18n
 */

import json_en from '../../res/locales/en/nobundle-npr.loc.json';

import json_en_GB from '../../res/locales/en-GB/nobundle-npr.loc.json';
import json_cs from '../../res/locales/cs/nobundle-npr.loc.json';
import json_de from '../../res/locales/de/nobundle-npr.loc.json';
import json_es from '../../res/locales/es/nobundle-npr.loc.json';
import json_fr from '../../res/locales/fr/nobundle-npr.loc.json';
import json_fr_CA from '../../res/locales/fr-CA/nobundle-npr.loc.json';
import json_it from '../../res/locales/it/nobundle-npr.loc.json';
import json_ja from '../../res/locales/ja/nobundle-npr.loc.json';
import json_ko from '../../res/locales/ko/nobundle-npr.loc.json';
import json_pl from '../../res/locales/pl/nobundle-npr.loc.json';
import json_pt_BR from '../../res/locales/pt-BR/nobundle-npr.loc.json';
import json_ru from '../../res/locales/ru/nobundle-npr.loc.json';
import json_tr from '../../res/locales/tr/nobundle-npr.loc.json';
import json_zh_HANS from '../../res/locales/zh-HANS/nobundle-npr.loc.json';
import json_zh_HANT from '../../res/locales/zh-HANT/nobundle-npr.loc.json';
import json_zh_HK from '../../res/locales/zh-HK/nobundle-npr.loc.json';
import json_nl from '../../res/locales/nl/nobundle-npr.loc.json';
import json_sv from '../../res/locales/sv/nobundle-npr.loc.json';
import json_da from '../../res/locales/da/nobundle-npr.loc.json';
import json_no from '../../res/locales/no/nobundle-npr.loc.json';

export var locales = {
  en: json_en,

  "en-GB": json_en_GB,
  cs: json_cs,
  de: json_de,
  es: json_es,
  fr: json_fr,
  "fr-CA": json_fr_CA,
  it: json_it,
  ja: json_ja,
  ko: json_ko,
  pl: json_pl,
  "pt-BR": json_pt_BR,
  ru: json_ru,
  tr: json_tr,
  "zh-HANS": json_zh_HANS,
  "zh-HANT": json_zh_HANT,
  "zh-HK": json_zh_HK,
  nl: json_nl,
  sv: json_sv,
  da: json_da,
  no: json_no };