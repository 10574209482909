function _createForOfIteratorHelper(o, allowArrayLike) {var it;if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) {if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {if (it) o = it;var i = 0;var F = function F() {};return { s: F, n: function n() {if (i >= o.length) return { done: true };return { done: false, value: o[i++] };}, e: function e(_e) {throw _e;}, f: F };}throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");}var normalCompletion = true,didErr = false,err;return { s: function s() {it = o[Symbol.iterator]();}, n: function n() {var step = it.next();normalCompletion = step.done;return step;}, e: function e(_e2) {didErr = true;err = _e2;}, f: function f() {try {if (!normalCompletion && it.return != null) it.return();} finally {if (didErr) throw err;}} };}function _unsupportedIterableToArray(o, minLen) {if (!o) return;if (typeof o === "string") return _arrayLikeToArray(o, minLen);var n = Object.prototype.toString.call(o).slice(8, -1);if (n === "Object" && o.constructor) n = o.constructor.name;if (n === "Map" || n === "Set") return Array.from(o);if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);}function _arrayLikeToArray(arr, len) {if (len == null || len > arr.length) len = arr.length;for (var i = 0, arr2 = new Array(len); i < len; i++) {arr2[i] = arr[i];}return arr2;} /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        * NPR related functions are moved to an extension
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        * to keep default LMV render context unadulterated, and
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        * to help customers to implement their own postprocess extensions.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        * This is achieved by extending RenderContextPostProcessExtension.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        */

var POSTPROC_STYLE_OFF = 0;
var POSTPROC_STYLE_EDGING = 1;
var POSTPROC_STYLE_CEL = 2;
var POSTPROC_STYLE_GRAPHITE = 3;
var POSTPROC_STYLE_PENCIL = 4;

var getResourceUrl = Autodesk.Viewing.Private.getResourceUrl;
var ShaderPass = Autodesk.Viewing.Private.ShaderPass;
var CelShader = Autodesk.Viewing.Private.CelShader;
var RenderContextPostProcessExtension = Autodesk.Viewing.Private.RenderContextPostProcessExtension;

var av = Autodesk.Viewing;

var NPRRenderContextExtension = function NPRRenderContextExtension(renderContext, viewer) {
  RenderContextPostProcessExtension.call(this, renderContext, viewer);

  // Initialize private variables
  var _pencilMap = null;
  var _graphiteMaps = [];
  var _pencilBackgroundMap = null;

  this.preloadPostProcessStyle = function (style) {
    if (style == undefined) {
      // if no argument given, load them all
      this.preloadPostProcessStyle("graphite");
      this.preloadPostProcessStyle("pencil");
      return;
    }
    switch (style) {
      case "graphite":
        if (_graphiteMaps[0] === undefined) {// TODO better way to test? Note it differs from the one below
          // LMV-2820 fix - we are in control of the URL, so to have Ninja function, we allow load from "another" site.
          THREE.ImageUtils.crossOrigin = '';
          for (var i = 0; i < 8; i++) {
            var file = getResourceUrl("extensions/NPR/res/postprocess/Graphite" + (i + 1) + ".png");
            _graphiteMaps[i] = THREE.ImageUtils.loadTexture(file);
            //_graphiteMaps[i] = THREE.ImageUtils.loadTexture("http://localhost:8000/build/res/postprocess/Graphite" + (i+1) + ".png"); 
            _graphiteMaps[i].wrapS = _graphiteMaps[i].wrapT = THREE.RepeatWrapping;
          }
          this.postProcPass.uniforms['tGraphite1'].value = _graphiteMaps[0];
          this.postProcPass.uniforms['tGraphite2'].value = _graphiteMaps[1];
          this.postProcPass.uniforms['tGraphite3'].value = _graphiteMaps[2];
          this.postProcPass.uniforms['tGraphite4'].value = _graphiteMaps[3];
          this.postProcPass.uniforms['tGraphite5'].value = _graphiteMaps[4];
          this.postProcPass.uniforms['tGraphite6'].value = _graphiteMaps[5];
          this.postProcPass.uniforms['tGraphite7'].value = _graphiteMaps[6];
          this.postProcPass.uniforms['tGraphite8'].value = _graphiteMaps[7];
        }
        break;
      case "pencil":
        if (_pencilMap === null) {// TODO better way to test?
          // LMV-2820 fix - we are in control of the URL, so to have Ninja function, we allow load from "another" site.
          THREE.ImageUtils.crossOrigin = '';
          _pencilMap = THREE.ImageUtils.loadTexture(getResourceUrl("extensions/NPR/res/postprocess/ColorPencil_02.png"));
          _pencilMap.wrapS = _pencilMap.wrapT = THREE.RepeatWrapping;
          _pencilBackgroundMap = THREE.ImageUtils.loadTexture(getResourceUrl("extensions/NPR/res/postprocess/paper2.jpg"));
          _pencilBackgroundMap.wrapS = _pencilBackgroundMap.wrapT = THREE.RepeatWrapping;

          this.postProcPass.uniforms['tFill'].value = _pencilMap;
          this.postProcPass.uniforms['tPaper'].value = _pencilBackgroundMap;
        }
        break;}

  };

  // returns 1 (or some other error code) on failure, 0 on success
  this.setPostProcessParameter = function (token, value) {
    this.changePostProcessParameter(token, value);

    this.viewer.impl.fireRenderOptionChanged();
    this.viewer.impl.invalidate(true);
  };


  this.changePostProcessParameter = function (token, value) {
    var _settings = this.renderContext.settings;

    switch (token) {
      case "style":
        this.setPostProcShaded(false);
        this.postProcPass.uniforms['style'].value = POSTPROC_STYLE_OFF;
        if (this.renderContext.depthTargetSupported()) {
          // put this code back in if we can't run with mobile
          // if _isWeakDevice, I guess we can't use? Needs ID buffer and normals/depths.
          // if ( _isWeakDevice ) {
          //     // sorry, not available on mobile - needs ID buffer and normal/depth buffer
          //     return 1;
          // } else {
          // load any textures that haven't been loaded.
          this.preloadPostProcessStyle(value);
          switch (value) {
            case "edging":
              this.setPostProcShaded(true);
              this.postProcPass.uniforms['style'].value = POSTPROC_STYLE_EDGING;
              break;
            case "cel":
              this.setPostProcShaded(true);
              this.postProcPass.uniforms['style'].value = POSTPROC_STYLE_CEL;
              break;
            case "graphite":
              this.setPostProcShaded(true);
              this.postProcPass.uniforms['style'].value = POSTPROC_STYLE_GRAPHITE;
              break;
            case "pencil":
              this.setPostProcShaded(true);
              this.postProcPass.uniforms['style'].value = POSTPROC_STYLE_PENCIL;
              break;}

          if (this.isPostProcShaded()) {
            _settings.idbuffer = true;
          } else {
            _settings.idbuffer = !this.renderContext.isWeakDevice(); // TODO are these good conditions?
          }

          this.renderContext.initPostPipeline(_settings.sao, _settings.antialias);
        }
        break;

      case "edges":
        this.postProcPass.uniforms['idEdges'].value = value ? 1 : 0;
        this.postProcPass.uniforms['normalEdges'].value = value ? 1 : 0;
        this.postProcPass.uniforms['depthEdges'].value = value ? 1 : 0;
        // turning off edges means we can use considerably less memory and fragment shading evaluations,
        // i.e., we can use normal-resolution off-screen targets, not 2x2 larger
        this.renderContext.initPostPipeline(_settings.sao, _settings.antialias);
        break;
      case "idEdges":
        this.postProcPass.uniforms['idEdges'].value = value ? 1 : 0;
        this.renderContext.initPostPipeline(_settings.sao, _settings.antialias);
        break;
      case "normalEdges":
        this.postProcPass.uniforms['normalEdges'].value = value ? 1 : 0;
        this.renderContext.initPostPipeline(_settings.sao, _settings.antialias);
        break;
      case "depthEdges":
        this.postProcPass.uniforms['depthEdges'].value = value ? 1 : 0;
        this.renderContext.initPostPipeline(_settings.sao, _settings.antialias);
        break;
      case "brightness":
        this.postProcPass.uniforms['brightness'].value = value;
        break;
      case "contrast":
        this.postProcPass.uniforms['contrast'].value = value;
        break;
      case "grayscale":
        this.postProcPass.uniforms['grayscale'].value = value ? 1 : 0;
        break;
      case "preserveColor":
        this.postProcPass.uniforms['preserveColor'].value = value ? 1 : 0;
        break;
      case "levels":
        this.postProcPass.uniforms['levels'].value = value;
        break;
      case "repeats":
        this.postProcPass.uniforms['repeats'].value = value;
        break;
      case "rotation": // 0.0 to 1.0, around circle (e.g. 0.5 == pi radians, 1.0 == 2*pi)
        this.postProcPass.uniforms['rotation'].value = value;
        break;
      case "outlineRadius":
        this.postProcPass.uniforms['outlineRadius'].value = value;
        break;
      case "outlineNoise":
        this.postProcPass.uniforms['outlineNoise'].value = value ? 1 : 0;
        break;
      default:
        console.error("setPostProcessParameter: parameter '" + token + "' is not valid.");}


    return 0;
  };

  // TODO what's a better return value if undefined? Or just issue a warning? Should there be a separate query function?
  this.queryPostProcessParameter = function (token, value) {
    if (this.postProcPass.uniforms[token] !== 'undefined') {
      return this.postProcPass.uniforms[token].value;
    } else {
      logger.warning("queryPostProcessParameter: parameter '" + token + "' is not valid");
      return value;
    }
  };

  this.load = function () {
    this.postProcPass = new ShaderPass(CelShader);
    this.renderContext.setNoDepthNoBlend(this.postProcPass);

    // Initialize the NPR related resources
    this.preloadPostProcessStyle();
  };

  this.unload = function () {

    this.changePostProcessParameter('style', null);

    // Need to do some cleanup here, once user deactive the extension
    // Can we remove all those resources to safe a little bit memory
    var _iterator = _createForOfIteratorHelper(_graphiteMaps),_step;try {for (_iterator.s(); !(_step = _iterator.n()).done;) {var texture = _step.value;
        texture.dispose();
      }} catch (err) {_iterator.e(err);} finally {_iterator.f();}

    _graphiteMaps = [];

    RenderContextPostProcessExtension.prototype.unload.call(this);
  };

  this.postProcessEdgesOn = function () {
    return (
      this.isPostProcShaded() && (
      this.postProcPass.uniforms["idEdges"].value !== 0 ||
      this.postProcPass.uniforms["normalEdges"].value !== 0 ||
      this.postProcPass.uniforms["depthEdges"].value !== 0));

  };

  this.getOrder = function () {
    return 10;
  };

  this.shouldRenderAfterOverlays = function () {
    return false;
  };
};

NPRRenderContextExtension.prototype = Object.create(RenderContextPostProcessExtension.prototype);
NPRRenderContextExtension.prototype.constructor = NPRRenderContextExtension;
av.GlobalManagerMixin.call(NPRRenderContextExtension.prototype);

export { NPRRenderContextExtension };